import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const pageQuery = graphql`
  query BlogDetailQuery($id: String!, $language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "subscribe", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    blog(id: { eq: $id }) {
      id
      content
      title
      languages {
        locale
        column_name
        value
      }
      remoteImage {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
      updated_at(fromNow: true, locale: $language)
    }
  }
`

interface BlogDetailPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        siteUrl: string
      }
    }
    blog: {
      id: string
      title: string
      languages: [
        {
          column_name: string
          locale: string
          value: string
        }
      ]
      remoteImage: IGatsbyImageData
      content: string
      updated_at: string
    }
  }
  pageContext: {
    language: string
  }
}

const BlogDetailPage = (props: BlogDetailPageProps) => {
  const { t } = useTranslation()
  const { language } = props.pageContext
  const post = props.data.blog
  const postImg = getImage(post.remoteImage)

  const getTitle = (): string => {
    let title = ''
    if (post.languages.length > 0) {
      post.languages.map((translation) => {
        if (translation.locale === language && translation.column_name === `title`) {
          title = translation.value
        } else {
          title = post.title
        }
      })
    }
    return title
  }
  return (
    <>
      <SEO
        title={getTitle()}
        meta={[
          {
            name: `og:image`,
            content: 'https://jasnita.com' + postImg?.images.fallback?.src,
          },
          {
            name: `twitter:image`,
            content: 'https://jasnita.com' + postImg?.images.fallback?.src,
          },
        ]}
      />

      <Hero>
        <h1 className="uk-heading-small">Blog</h1>
        <p>Redefining your customer experiences with Jasnita CX solutions.</p>
      </Hero>

      <section className="uk-section">
        <div className="uk-container">
          <div uk-grid="">
            <div className="uk-width-expand@m">
              <section key={post.id} className="uk-section-small">
                <h2>
                  {post.languages.length > 0
                    ? post.languages.map((translation) => {
                        if (translation.locale === language && translation.column_name === `title`) {
                          {
                            return translation.value
                          }
                        }
                      })
                    : post.title}
                </h2>
                <section className="uk-section-small">
                  <div className="uk-card uk-card-primary uk-card-body">
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-expand@m">
                        <h3>{t('You can directly contact our team for a further presentation')}.</h3>
                      </div>
                      <div className="uk-width-1-3@m uk-text-center">
                        <a
                          className="uk-button uk-button-primary uk-margin-top"
                          href="https://api.whatsapp.com/send/?phone=6281119015555&text=Hai+Jasnita%2C+Saya+tertarik+dengan+produk+anda.+Saya+tau+dari+website+https://jasnita.com/&type=phone_number&app_absent=0"
                        >
                          {t('Contact Us')}
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="uk-child-width-expand" uk-grid="">
                  {/* {post.remoteImage && postImg && (
                    <GatsbyImage
                      className="uk-width-1-1 uk-first-column"
                      imgStyle={{ left: `auto`, right: `auto`, borderRadius: `10px` }}
                      style={{ borderRadius: '10px' }}
                      image={postImg}
                      alt={post.title}
                    />
                  )} */}
                  <div>
                    <div>
                      {post.languages.length > 0 ? (
                        post.languages.map((translation, index) => {
                          if (translation.locale === language && translation.column_name === `content`) {
                            {
                              return <div key={index} dangerouslySetInnerHTML={{ __html: translation.value }}></div>
                            }
                          }
                        })
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
                      )}
                    </div>
                    <p className="uk-article-meta">
                      {t('Written on')} {post.updated_at}.
                    </p>
                  </div>
                </div>
              </section>
            </div>

            <aside className="uk-width-1-3@m">
              <div className="uk-card uk-card-default uk-card-body uk-width-1-1">
                <div>
                  <form>
                    <h4>{t('Newsletter')}</h4>
                    <fieldset className="uk-fieldset">
                      <p>{t('Subscribe to receive our updates')}</p>
                      <div className="uk-margin">
                        <input className="uk-input" type="email" placeholder={t('Enter your email')} />
                      </div>
                      <div className="uk-margin">
                        <button className="uk-button uk-button-primary">{t('Subscribe')}</button>
                      </div>
                    </fieldset>
                  </form>
                </div>
                {/* <div>
                    <h4>Categories</h4>
                    <ul className="uk-list uk-list-disc">
                      <li>List item 1</li>
                      <li>List item 2</li>
                      <li>List item 3</li>
                    </ul>
                  </div> */}
                {/* <div>
                    <h4>Popular Posts</h4>
                    <ul className="uk-list uk-list-disc">
                      <li>List item 1</li>
                      <li>List item 2</li>
                      <li>List item 3</li>
                    </ul>
                  </div> */}
              </div>
            </aside>
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogDetailPage
